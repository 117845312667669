import {AxiosResponse} from "axios";
import {EmploymentRole, ResolutionExpert} from "../../../../declerations/server";
import {axiosSpringInstance} from "../../../ServerApiManager";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../../base/useBaseQuery";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../../base/useBaseMutation";
type Variables = {};
type UpdateResolutionExpertRequest = {
  id: number;
  employmentRole: EmploymentRole;
};
export const getAllResolutionExperts = async (): Promise<ResolutionExpert[]> => {
  const response = await axiosSpringInstance.get<ResolutionExpert[], AxiosResponse<ResolutionExpert[]>>(
    `/rex-management/resolution-experts`
  );
  return response.data;
};

export const useGetAllResolutionExperts = (
  variables: Variables,
  options?: BaseQueryOptions<ResolutionExpert[], Variables>
): BaseUseQueryResult<ResolutionExpert[]> => {
  return useBaseQuery<ResolutionExpert[], Variables>(
    ["resolution-experts"],
    getAllResolutionExperts,
    variables,
    options
  );
};

export const getRexEmails = async (variables: Variables): Promise<string[]> => {
  const result = await getAllResolutionExperts();
  return result.filter((re) => re.employment_role === "resolution_expert").map((re) => re.email);
};

export const useGetRexEmails = (
  variables: Variables,
  options?: BaseQueryOptions<string[], Variables>
): BaseUseQueryResult<string[]> => {
  return useBaseQuery<string[], Variables>(["rex-emails"], getRexEmails, variables, options);
};

export const updateResolutionExpert = async ({
  id,
  employmentRole,
}: UpdateResolutionExpertRequest): Promise<ResolutionExpert> => {
  const response = await axiosSpringInstance.patch<ResolutionExpert, AxiosResponse<ResolutionExpert>>(
    `/rex-management/resolution-experts/${id}`,
    {employment_role: employmentRole}
  );
  return response.data;
};

export const useUpdateResolutionExpert = (
  options?: BaseMutationOptions<ResolutionExpert, UpdateResolutionExpertRequest>
): BaseUseMutationResult<ResolutionExpert, UpdateResolutionExpertRequest> =>
  useBaseMutation(updateResolutionExpert, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["resolution-experts"],
  });
