import {useIntl} from "react-intl";
import React, {FunctionComponent} from "react";
import {Controller, useForm} from "react-hook-form";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import FormValidations from "../../../../helpers/FormValidations";
import {TextField, Dialog} from "../../../../bounce-ui/consumer";
import {EMAIL_REGEX} from "../../../../constants/regex";

interface IEditContactDialog {
  type: "tel" | "email";
  value: string;
  isLoading: boolean;
  onValueUpdated?: (value: string) => void;
}

type FormValues = {
  value: string;
};

const EditContactDialog: FunctionComponent<IEditContactDialog & NiceModalHocProps> = NiceModal.create(
  ({value, type, isLoading, onValueUpdated}) => {
    const modal = useModal();
    const intl = useIntl();

    const {control, handleSubmit, watch} = useForm<FormValues>();
    const currValue = watch("value");

    const onSubmit = handleSubmit((data): void => {
      onValueUpdated?.(data.value);

      modal.resolve(true);
      modal.hide();
    });

    return (
      <Dialog
        title={"Update contact info"}
        buttonText={"Update"}
        onClick={onSubmit}
        disabled={!currValue || currValue === value}
        autoClose={false}
      >
        <form onSubmit={onSubmit} style={{width: "100%"}}>
          <Controller
            name="value"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
              validate: (value) =>
                type === "tel" && FormValidations.validateTelephone("", value)
                  ? intl.formatMessage({id: "phoneInvalid"})
                  : undefined,
              ...(type === "email" && {
                pattern: {value: EMAIL_REGEX, message: intl.formatMessage({id: "emailInvalid"})},
              }),
            }}
            control={control}
            defaultValue={value}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={type === "tel" ? "Phone number" : "Email"}
                  autoComplete="off"
                  autoFocus
                  error={invalid}
                  helperText={error?.message}
                />
              );
            }}
          />
        </form>
      </Dialog>
    );
  }
);

export default EditContactDialog;
