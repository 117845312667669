import {FunctionComponent, lazy, Suspense} from "react";
import {Navigate, Outlet, useRoutes} from "react-router-dom";
import GuestGuard from "./guards/GuestGuard";
import AuthGuard from "./guards/AuthGuard";
import LoadingScreen from "../components/common/LoadingScreen";
import {urls} from "./urls";
import componentLoader from "../helpers/ComponentLoader";
import AccountLayout from "../layout/account/AccountLayout";
import {useUser} from "../contexts";
import UiGuard from "./guards/UiGuard";
import BackofficeLayout from "../layout/agent";
import BackofficeGuard from "./guards/BackofficeGuard";
import {flexBaseUrl} from "constants/defaultValues";
import SpringLayout from "../layout/spring/SpringLayout";

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const Redirectable: FunctionComponent<{url: string}> = ({url}) => {
  window.location.href = url;
  return null;
};

export default function Router() {
  const {loggedIn} = useUser();
  return useRoutes([
    {
      path: "/",
      element: <Navigate to={loggedIn ? urls.accounts.url : urls.login.url} replace />,
    },
    // Multi Accounts
    {
      path: urls.accounts.path,
      element: (
        <AuthGuard>
          <UiGuard>
            <AccountLayout />
          </UiGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Accounts />,
        },
      ],
    },
    // Backoffice Routes
    {
      path: urls.backofficeLogin.path,
      element: <Redirectable url={`${flexBaseUrl}/google/login`} />,
    },
    {
      path: urls.backoffice.path,
      element: (
        <BackofficeGuard>
          <BackofficeLayout />
        </BackofficeGuard>
      ),
      children: [
        {
          path: urls.searchPanel.path,
          element: <SearchPanel />,
        },
        {
          path: urls.informationPanel.path,
          element: <InformationPanel />,
        },
        {
          path: urls.history.path,
          element: <RexHistory />,
        },
        {path: "*", element: <Navigate to={urls.error.url} replace />},
      ],
    },
    {
      path: urls.spring.path,
      element: (
        <BackofficeGuard>
          <SpringLayout />
        </BackofficeGuard>
      ),
      children: [
        {
          path: urls.dscBulkOperations.path,
          element: <BulkOperations />,
        },
        {
          path: urls.importPricing.path,
          element: <ImportPricing />,
        },
        {
          path: urls.generatePricing.path,
          element: <GeneratePricingWashReport />,
        },
        {
          path: urls.anonymousCommunications.path,
          element: <AnonymousCommunications />,
        },
        {
          path: urls.representationCompanies.path,
          element: <RepresentationCompanies />,
        },
        {
          path: urls.searchPanelSpring.path,
          element: <SearchPanel />,
        },
        {
          path: urls.informationPanelSpring.path,
          element: <InformationPanel />,
        },
        {
          path: urls.rexManagement.path,
          element: <RexManagement />,
        },
        {
          path: urls.mediaUpload.path,
          element: <MediaUpload />,
        },
        {
          path: urls.importUsers.path,
          element: <ImportUsers />,
        },
        {
          path: urls.importPayments.path,
          element: <ImportPayments />,
        },
        {
          path: urls.washing.path,
          element: <Washing />,
        },
        {
          path: urls.bankruptcies.path,
          element: <Bankruptcies />,
        },
        {
          path: urls.conversationsSpring.path,
          element: <RexHistory />,
        },
        {
          path: "",
          element: <Navigate to={urls.searchPanelSpring.url} replace />,
        },
      ],
    },

    // Auth Routes
    {
      path: urls.auth.path,
      element: (
        <GuestGuard>
          <UiGuard>
            <AccountLayout />
          </UiGuard>
        </GuestGuard>
      ),
      children: [
        {
          path: urls.login.path,
          element: <Login />,
        },
        {
          path: urls.deprecatedLogin.path,
          element: <Login />,
        },
        {
          path: urls.verifyAccount.path,
          element: <VerifyAccount />,
        },
        {
          path: urls.verifyCodeFromUrl.path,
          element: <VerifyCode />,
        },
        {
          path: urls.verifyCodeFromUrlWithCampaign.path,
          element: <VerifyCode />,
        },
        {
          path: urls.verifyAnonymousAccount.path,
          element: <VerifyAnonymousAccount />,
        },
        {
          path: urls.verifyCode.path,
          element: <VerifyCode />,
        },
        {path: "", element: <Navigate to={urls.error.url} replace />},
        {path: "*", element: <Navigate to={urls.error.url} replace />},
      ],
    },
    // Account Routes
    {
      path: urls.account.path,
      element: (
        <AuthGuard>
          <UiGuard>
            <AccountLayout />
          </UiGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Accounts />,
        },
        {
          path: urls.account.path,
          element: <Accounts />,
        },
        {
          path: urls.accountMain.path,
          element: <Account />,
        },
        {
          path: urls.accounts.path,
          element: <Accounts />,
        },
        {
          path: urls.summaryPage.path,
          element: <SummaryPage />,
        },
        {
          path: urls.accountInformation.path,
          element: <AccountInformation />,
        },
        {
          path: urls.accountDashboard.path,
          element: <AccountDashboardPage />,
        },
        {
          path: urls.dispute.path,
          element: <DisputePage />,
        },
        {
          path: urls.disputeSuccess.path,
          element: <DisputeSuccess />,
        },
        {
          path: urls.presetPlanCheckout.path,
          element: <PresetPlanCheckout />,
        },
        {
          path: urls.paymentSuccess.path,
          element: <PaymentSuccess />,
        },
        {
          path: urls.calendarReminders.path,
          element: <CalendarReminders />,
        },
        {path: "*", element: <Navigate to={urls.error.url} replace />},
        // Payment plan v2
        {
          path: urls.planOptions.path,
          element: <PlanOptions />,
        },
        {
          path: urls.choosePlan.path,
          element: <ChoosePlan />,
        },
        {
          path: urls.singlePayment.path,
          element: <SinglePayment />,
        },
        {
          path: urls.partialPayment.path,
          element: <CustomPayment />,
        },
        {
          path: urls.hardshipIntro.path,
          element: <HardshipIntro />,
        },
        {
          path: urls.hardshipPlans.path,
          element: <HardshipPlans />,
        },
      ],
    },
    // Guest payment link routes
    {
      path: urls.guestPaymentLinkRoot.path,
      element: (
        <GuestGuard>
          <UiGuard>
            <AccountLayout />
          </UiGuard>
        </GuestGuard>
      ),
      children: [
        {
          path: urls.guestPaymentLinkCheckout.path,
          element: <GuestPaymentPlanCheckout />,
        },
        {
          path: urls.guestPaymentLinkSuccess.path,
          element: <PaymentSuccess />,
        },
      ],
    },
    // General Routes
    {
      path: "*",
      element: <Outlet />,
      children: [
        {path: urls.error.path, element: <Error />},
        {path: "*", element: <Navigate to={urls.error.url} replace />},
      ],
    },
    {path: "*", element: <Navigate to={urls.error.url} replace />},
  ]);
}

// IMPORT COMPONENTS

// AUTH
const Login = Loadable(lazy(() => componentLoader(() => import("../pages/auth/login/Login"))));
const VerifyCode = Loadable(lazy(() => componentLoader(() => import("../pages/auth/login/VerifyCode"))));
const VerifyAccount = Loadable(lazy(() => componentLoader(() => import("../pages/auth/login/VerifyAccount"))));
const VerifyAnonymousAccount = Loadable(
  lazy(() => componentLoader(() => import("../pages/auth/login/VerifyAnonymousAccount")))
);

// BACKOFFICE
const SearchPanel = Loadable(lazy(() => componentLoader(() => import("../pages/backoffice/search-panel/SearchPanel"))));

const RexHistory = Loadable(lazy(() => componentLoader(() => import("../pages/backoffice/rex-history/RexHistory"))));
const InformationPanel = Loadable(
  lazy(() => componentLoader(() => import("../pages/backoffice/information-panel/InformationPanel")))
);

// SPRING
const MediaUpload = Loadable(
  lazy(() => componentLoader(() => import("../pages/spring/lender-operations/MediaUpload")))
);

const ImportUsers = Loadable(
  lazy(() => componentLoader(() => import("../pages/spring/lender-operations/ImportUsers")))
);
const Washing = Loadable(lazy(() => componentLoader(() => import("../pages/spring/lender-operations/Washing"))));
const ImportPayments = Loadable(
  lazy(() => componentLoader(() => import("../pages/spring/lender-operations/ImportPayments")))
);

const ImportPricing = Loadable(lazy(() => componentLoader(() => import("../pages/spring/pricing/ImportPricing"))));
const GeneratePricingWashReport = Loadable(
  lazy(() => componentLoader(() => import("../pages/spring/pricing/GeneratePricingWashReport")))
);
const RexManagement = Loadable(
  lazy(() => componentLoader(() => import("../pages/spring/rex-management/RexManagement")))
);

const Bankruptcies = Loadable(
  lazy(() => componentLoader(() => import("../pages/spring/bankruptcies/BankruptciesPanel")))
);

const BulkOperations = Loadable(lazy(() => componentLoader(() => import("../pages/spring/dsc/BulkOperations"))));
const RepresentationCompanies = Loadable(
  lazy(() => componentLoader(() => import("../pages/spring/representation-companies/RepresentationCompanies")))
);
const AnonymousCommunications = Loadable(
  lazy(() => componentLoader(() => import("../pages/spring/dsc/AnonymousCommunications")))
);

// ACCOUNT
const Account = Loadable(lazy(() => componentLoader(() => import("../pages/account/home-page/Account"))));
const Accounts = Loadable(lazy(() => componentLoader(() => import("../pages/account/accounts/Accounts"))));
const AccountInformation = Loadable(lazy(() => componentLoader(() => import("../pages/account/home-page/HomePage"))));
const AccountDashboardPage = Loadable(
  lazy(() => componentLoader(() => import("../pages/account/account-dashboard/AccountDashboardPage")))
);
const DisputePage = Loadable(lazy(() => componentLoader(() => import("../pages/account/dispute/DisputePage"))));
const DisputeSuccess = Loadable(lazy(() => componentLoader(() => import("../pages/account/dispute/DisputeSuccess"))));
const PresetPlanCheckout = Loadable(
  lazy(() => componentLoader(() => import("../pages/account/payment/PresetPlanCheckout")))
);
const GuestPaymentPlanCheckout = Loadable(
  lazy(() => componentLoader(() => import("../pages/payment-plan/GuestPaymentPlanCheckout")))
);
const PaymentSuccess = Loadable(lazy(() => componentLoader(() => import("../pages/account/success/PaymentSuccess"))));
const CalendarReminders = Loadable(
  lazy(() => componentLoader(() => import("../pages/account/calendar-reminders/CalendarReminders")))
);
// PAYMENT PLAN V2
const PlanOptions = Loadable(
  lazy(() => componentLoader(() => import("../pages/account/payment-plans-v2/PlanOptions")))
);
const ChoosePlan = Loadable(
  lazy(() => componentLoader(() => import("../pages/account/payment-plans-v2/PaymentPlanComponents/ChoosePlan")))
);
const SinglePayment = Loadable(
  lazy(() => componentLoader(() => import("../pages/account/payment-plans-v2/SinglePayment")))
);
const CustomPayment = Loadable(
  lazy(() => componentLoader(() => import("../pages/account/payment-plans-v2/PartialPayment")))
);
const HardshipIntro = Loadable(
  lazy(() => componentLoader(() => import("../pages/account/payment-plans-v2/PaymentPlanComponents/HardshipIntro")))
);
const HardshipPlans = Loadable(
  lazy(() => componentLoader(() => import("../pages/account/payment-plans-v2/PaymentPlanComponents/HardshipPlans")))
);
const SummaryPage = Loadable(
  lazy(() => componentLoader(() => import("../pages/account/payment-plans-v2/PaymentPlanComponents/Summary/Summary")))
);
// ERRORS
const Error = Loadable(lazy(() => componentLoader(() => import("../pages/general/error/ErrorPage"))));
