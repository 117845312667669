import {axiosFlexInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {PaymentPlanFrequency} from "../../../declerations/enums";

type Variables = {
  accountId: string;
  startDate: string;
  frequency: PaymentPlanFrequency;
  numberOfInstallments: number;
  installmentSize: number;
  totalAmount: number;
};

type Data = string;

export const getEndDateForNonLinearPlan = async (variables: Variables): Promise<Data> => {
  const result = await axiosFlexInstance.get<Data, AxiosResponse<Data>>(`/end_date`, {
    params: {
      account_id: variables.accountId,
      start_date: variables.startDate,
      installment_frequency: variables.frequency,
      number_of_installments: variables.numberOfInstallments,
      installment_size: Math.floor(variables.installmentSize * 100),
      total_amount: variables.totalAmount * 100,
    },
  });
  return result.data;
};

export const useGetEndDateForNonLinearPlan = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => useBaseMutation(getEndDateForNonLinearPlan, options);
