import React from "react";
import {ICustomizedInstallment} from "../../backoffice/information-panel/tabs/payment-plan-tab/components/custom-view/useCustomView";
import {NonLinearPaymentPlan, PaymentPlanOffer} from "../../../declerations/server/payment_plan_models";
import {PaymentDetails} from "../../../declerations/server";
import NiceModal from "@ebay/nice-modal-react";
import PaymentFailedDialog from "./components/PaymentFailedDialog";

export interface IResponseData {
  success: boolean;
  error_message: string | null;
}

interface PaymentContextProps {
  buttonTouched: boolean;
  setButtonTouched: (value: boolean) => void;
  isLoading?: boolean;
  showOneCentLabel?: boolean;
  showLargeSplitLabel?: boolean;
  disableButton?: boolean;
  disableACHAccountOwnerPrePopulation?: boolean;
}
export const PaymentContext = React.createContext<PaymentContextProps>(undefined!);

const openPaymentFailedDialog = (error: string) => {
  NiceModal.show(PaymentFailedDialog, {error});
};

export const formatInput = (inputValue: any) => {
  const inputValueWithoutDashes = inputValue.replace(/-/g, "");
  const formattedValue = inputValueWithoutDashes.replace(/(.{4})/g, "$1-");
  return formattedValue.replace(/-$/, "");
};

const mapFrequencyToTextId: any = {
  weekly: "weekly",
  bi_weekly: "biWeekly",
  first_and_fifteenth: "firstAndFifteenth",
  monthly: "monthly",
};

const createNonLinearPlan = (
  customInstallment: ICustomizedInstallment,
  startDate: string,
  paymentPlanOffer: PaymentPlanOffer,
  paymentDetails: PaymentDetails
): NonLinearPaymentPlan => {
  return {
    installments: [
      {
        due_date: customInstallment.startDate,
        amount_cents: customInstallment.amountToPay,
      },
    ],
    payment_plans: [
      {
        start_date: startDate,
        installment_frequency: paymentPlanOffer.installment_frequency,
        number_of_installments: paymentPlanOffer.number_of_installments,
        installment_size: paymentPlanOffer.installment_size,
      },
    ],
    payment_details: paymentDetails,
  };
};

const getPlanType = (isSinglePayment: boolean, isHardship: boolean) => {
  if (isSinglePayment) {
    return "one_time_payment";
  }
  if (isHardship) {
    return "hardship_plan";
  }
  return "payment_plan";
};

export {openPaymentFailedDialog, mapFrequencyToTextId, createNonLinearPlan, getPlanType};
